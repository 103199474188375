html {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Segoe UI',
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.root {
  width: 100%;
  height: 100%;
  margin: 0;
}

@font-face {
  font-family: Roboto;
  font-weight: 100 900;
  font-style: normal;

  src: local('Roboto'), url('./assets/fonts/Roboto/Roboto-VariableFont_wdth,wght.ttf') format('truetype');
}